// eslint-disable-next-line no-redeclare,no-unused-vars
function setData(
  object,
  id,
  key,
  value,
  triggered,
  objectSub,
  save,
  update,
  param = {}
) {
  var cache = getObjectValue(data, [object, id, key]);
  var same = false;

  if (cache === value) same = true;
  else if (!isset(cache) && !isset(value)) same = true;
  else if (!isset(cache)) same = false;
  else if (isset(cache) && !isset(value)) same = false;
  else if (typeof cache != typeof value) same = false;
  else if (isArray(value) || isPlainObject(value)) {
    if (object == param.object && arrayEqual(cache, value)) same = true;
    else if (object != param.object && arrayEqual(cache, value, true))
      same = true;
    else if (
      cache[cache.length - 1] == 'new' &&
      cache.length - 1 == value.length
    )
      same = true;
  } else if (cache == value) same = true;

  // update
  if (!same || update) {
    if (!data[object]) data[object] = {};
    if (!data[object][id]) data[object][id] = {};
    data[object][id][key] = value;
    var settings = {};
    if (getObjectValue(fields, [object, key]))
      settings = getFieldSettings(
        {
          object: object,
          objectSub: objectSub,
        },
        key
      );
    var els = getObjectValue(elements, [object, id, key + '_els']);
    if (els)
      $.each(els, function (i, el) {
        if (!el) return;
        else if (
          triggered &&
          triggered.is(el) &&
          !triggered.hasClass('datepicker') &&
          !triggered.hasClass('datetimepicker')
        )
          return;
        // else if (triggered && triggered.line && el.line && triggered.line.is(el.line)) return;
        else if (!el.parents('body')[0]) {
          delete elements[object][id][key + '_els'][i];
          return;
        }
        // else if (settings.field == 'table' && settings.refTable) return;

        // update element
        var value = data[object][id][key];
        if (
          value &&
          key == 'disabled' &&
          el.view &&
          el.view.object &&
          el.view.object != object
        ) {
          el.val(null);
        } else if (str_ends_with(key, '_list')) {
          if (update) el.getList();
          else el.valList(value, true);
        } else if (settings.multiple && el.line && el.line.update) {
          if (isArray(value)) el.line.update(value, id);
        } else if (settings.refTable && el.line && el.line.update)
          el.line.update(value, id);
        else el.val(value, object, id, key);
        if (save && !i && el.save) el.save();
      });
  }
}
